import React from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';
import Calendar from 'images/outline/large/calendar.svg';
import LargeClock from 'images/outline/large/clock.svg';
import Clock from 'images/outline/small/clock.svg';

import useStore from 'store';

import DateSelect from './DateSelect';
import FilterSheet from './FilterSheet';
import Pill from './Pill';
import TimeSelect from './TimeSelect';

export default function AvailabilityFilter({ show, onClose }) {
  const date = useStore((store) => store.hostsFilter.date);
  const timeFrom = useStore((store) => store.hostsFilter.timeFrom);
  const timeTo = useStore((store) => store.hostsFilter.timeTo);

  const setHostsFilter = useStore((store) => store.setHostsFilter);
  const resetHostsFilterByKey = useStore(
    (store) => store.resetHostsFilterByKey
  );

  const [internalDate, setInternalDate] = React.useState(date);
  const [internalTimeFrom, setInternalTimeFrom] = React.useState(timeFrom);
  const [internalTimeTo, setInternalTimeTo] = React.useState(timeTo);

  React.useEffect(
    () => {
      date !== internalDate && setInternalDate(date);
      timeFrom !== internalTimeFrom && setInternalTimeFrom(timeFrom);
      timeTo !== internalTimeTo && setInternalTimeTo(timeTo);
    },
    // Don't run effect when internal date and/or time range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, timeFrom, timeTo]
  );

  const handleReset = () => {
    resetHostsFilterByKey('date');
    resetHostsFilterByKey('timeFrom');
    resetHostsFilterByKey('timeTo');
    onClose();
  };
  const handleSave = () => {
    setHostsFilter({
      date: internalDate,
      timeFrom: internalTimeFrom,
      timeTo: internalTimeTo,
    });
    onClose();
  };

  return (
    <FilterSheet
      title="People"
      show={show}
      onSave={handleSave}
      onReset={handleReset}
      onClose={onClose}
    >
      <div className="grid gap-4">
        <div>
          <label className="flex items-center space-x-4">
            <span hidden className="sr-only">
              Date
            </span>
            <div
              className={clsx('flex items-center justify-center w-8 shrink-0')}
            >
              <Calendar className="w-5" />
            </div>
            <DateSelect
              value={internalDate}
              onChange={(e) => setInternalDate(e.target.value)}
            />
          </label>
        </div>

        <div>
          <label className="flex items-center space-x-4">
            <span hidden className="sr-only">
              Time
            </span>
            <div
              className={clsx('flex items-center justify-center w-8 shrink-0')}
            >
              <LargeClock className="w-5" />
            </div>
            <TimeSelect
              value={internalTimeFrom}
              onChange={(e) => setInternalTimeFrom(e.target.value)}
            />

            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              className="shrink-0"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5835 8.78418L10.5835 4.78418L6.5835 0.78418"
                stroke="currentColor"
              />
              <path d="M10.7085 4.78418H0.833496" stroke="currentColor" />
            </svg>

            <TimeSelect
              value={internalTimeTo}
              onChange={(e) => setInternalTimeTo(e.target.value)}
              placeholder="End time"
            />
          </label>
        </div>
      </div>
    </FilterSheet>
  );
}

export const AvailabilityFilterButton = ({ onClick }) => {
  const date = useStore((store) => store.hostsFilter.date);
  const timeFrom = useStore((store) => store.hostsFilter.timeFrom);
  const timeTo = useStore((store) => store.hostsFilter.timeTo);

  const label = React.useMemo(() => {
    if (!date && !timeFrom && !timeTo) {
      return 'Any time';
    }

    return [
      date && dayjs(date).format('MMM D'),
      timeFrom && !timeTo && `from ${timeFrom}`,
      timeTo && !timeFrom && `to ${timeTo}`,
      timeFrom && timeTo && `${timeFrom}-${timeTo}`,
    ]
      .filter(Boolean)
      .join(', ');
  }, [date, timeFrom, timeTo]);

  return (
    <Pill active={date || timeFrom || timeTo} onClick={onClick}>
      <Clock className="mr-2" />
      {label}
    </Pill>
  );
};
