import create from 'zustand';

export const HOSTS_FILTER_DEFAULTS = {
  date: undefined,
  timeFrom: undefined,
  timeTo: undefined,
  workType: undefined,
  attendees: 1,
  showNearby: false,
};

const useStore = create((set) => ({
  hostsFilter: { ...HOSTS_FILTER_DEFAULTS },
  resetHostsFilterByKey: (key) =>
    set((state) => ({
      hostsFilter: { ...state.hostsFilter, [key]: HOSTS_FILTER_DEFAULTS[key] },
    })),
  clearAllHostsFilter: () =>
    set(() => ({ hostsFilter: { ...HOSTS_FILTER_DEFAULTS } })),
  setHostsFilter: (update) =>
    set((state) => ({ hostsFilter: { ...state.hostsFilter, ...update } })),
}));

export default useStore;
