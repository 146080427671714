import clsx from 'clsx';
import times from 'lodash/times';

export const SkeletonDisplayText = ({
  size = 'md',
  fullWidth = true,
  className,
}) => (
  <div
    className={clsx(
      'relative rounded-sm bg-gray-100 animate-pulse max-w-[128px]',
      fullWidth && 'w-full',
      size === 'sm' && 'h-4',
      size === 'md' && 'h-5',
      size === 'lg' && 'h-6',
      size === 'xl' && 'h-8',
      className
    )}
  />
);

export const SkeletonBodyText = ({ size = 'md', lines = 3, className }) => (
  <div className="w-full space-y-2 animate-pulse">
    {times(lines, (index) => (
      <div
        key={index}
        className={clsx(
          'relative rounded-sm bg-gray-100',
          lines > 1 && 'last:w-4/5',
          size === 'sm' && 'h-4',
          size === 'md' && 'h-5',
          size === 'lg' && 'h-6',
          size === 'xl' && 'h-10',
          className
        )}
      />
    ))}
  </div>
);

export const SkeletonThumbnail = ({ size = 'sm', className }) => (
  <div
    className={clsx(
      'relative w-full rounded-sm bg-gray-100 animate-pulse',
      size === 'sm' && 'w-8 h-8',
      size === 'md' && 'w-24 h-24',
      size === 'lg' && 'w-32 h-32',
      className
    )}
  />
);

export const SkeletonImage = ({
  aspect = 'aspect-w-9 aspect-h-5',
  className,
}) => (
  <div
    className={clsx(
      'relative w-full bg-gray-100 animate-pulse',
      aspect,
      className
    )}
  />
);
