import { HostsQuery } from 'queries';
import { useQuery } from 'urql';

import useUser from './useAuth';

export default function useHosts(filter) {
  // NOTE: Calling this hook guarantees redirect
  // to login if user is not authenticated
  useUser();

  const variables = {
    tagId: filter.workType,
    personCount: filter.attendees,
    availability: {
      startAt: filter.timeFrom,
      endAt: filter.timeTo,
      date: filter.date,
    },
  };

  const [result] = useQuery({
    query: HostsQuery,
    variables: variables,
  });

  return result;
}
