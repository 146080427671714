import React from 'react';

import clsx from 'clsx';

export default function Pill({ onClick, disabled, active, children }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        'inline-flex items-center px-4 py-2 rounded-full text-sm font-medium',
        disabled
          ? 'bg-gray-100 text-gray-250'
          : active
          ? 'bg-spoto text-white'
          : 'bg-gray-100'
      )}
    >
      {children}
    </button>
  );
}
