import React from 'react';

import Head from 'next/head';
import Link from 'next/link';

import useHosts from 'ctx/useHosts';
import GridIcon from 'images/grid.svg';
import MapIcon from 'images/map.svg';
import Layout from 'layouts/Home';

import useStore from 'store';

import { MutedButton } from 'components/Button';
import HostsFilter from 'components/Filter/FilterBar';
import HostCard from 'components/HostCard';
import { SkeletonDisplayText } from 'components/Skeleton';
import { StickyTop } from 'components/Sticky';

const LayoutToggle = ({ href, variant }) => {
  const variants = {
    List: <GridIcon className="mr-2" />,
    Map: <MapIcon className="mr-2" />,
  };

  if (!(variant || Object.keys(variants).includes(variant))) return null;

  return (
    <Link href={href} passHref>
      <MutedButton>
        {variants[variant]}
        {variant}
      </MutedButton>
    </Link>
  );
};

const Grid = ({ children }) => (
  <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">{children}</div>
);

const HostsList = ({ hosts = [] }) => (
  <Grid>
    {hosts.map(
      ({ id, name, displayAddress, coverImage: { path: imagePath } }) => (
        <HostCard
          key={name}
          imageSrc={process.env.NEXT_PUBLIC_SPOTO_API_URL + imagePath}
          name={name}
          address={displayAddress}
          url={`/hosts/${id}`}
        />
      )
    )}
  </Grid>
);
const LoadingState = () => (
  <Grid>
    {[...Array(3).keys()].map((idx) => (
      <HostCard.Skeleton key={idx} />
    ))}
  </Grid>
);

function Page() {
  const hostsFilter = useStore((store) => store.hostsFilter);
  const { data } = useHosts(hostsFilter);

  const hosts = data?.hosts;

  return (
    <React.Fragment>
      <Head>
        <title>Spoto</title>
      </Head>

      <div className="bg-white">
        <div className="bg-white container pt-4 md:hidden">
          <h1 className="font-bold text-2xl">
            Book a work friendly spot at top locations
          </h1>
        </div>

        <StickyTop>
          <div className="flex items-center">
            <HostsFilter />
            <div className="shrink-0 ml-auto hidden md:block">
              <LayoutToggle href="/map" variant="Map" />
            </div>
          </div>
        </StickyTop>

        <div className="bg-white container py-4 md:py-8">
          <div className="space-y-4">
            <div className="hidden md:block">
              <h1 className="font-bold text-2xl">
                Book a work friendly spot at top locations
              </h1>
            </div>
            {hosts ? (
              <div className="text-gray-500">{hosts.length} spots</div>
            ) : (
              <SkeletonDisplayText size="lg" />
            )}

            {hosts?.length > 0 ? <HostsList hosts={hosts} /> : <LoadingState />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Page.getLayout = (page) => {
  return (
    <Layout
      headerProps={{
        outletRight: <LayoutToggle href="/map" variant="Map" />,
      }}
    >
      {page}
    </Layout>
  );
};

export default Page;
export { LayoutToggle };
