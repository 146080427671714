import React from 'react';

import clsx from 'clsx';
import Person from 'images/outline/small/person.svg';

import useStore from 'store';

import FilterSheet from './FilterSheet';
import Pill from './Pill';

const MAX_ATTENDEES = 10;

export default function AttendeesFilter({ show, onClose }) {
  const attendees = useStore((store) => store.hostsFilter.attendees);
  const setHostsFilter = useStore((store) => store.setHostsFilter);
  const resetHostsFilterByKey = useStore(
    (store) => store.resetHostsFilterByKey
  );

  const setAttendees = (a) => setHostsFilter({ attendees: a });
  const handleSave = onClose;
  const handleReset = () => {
    resetHostsFilterByKey('attendees');
    onClose();
  };

  return (
    <FilterSheet
      title="People"
      show={show}
      onSave={handleSave}
      onReset={handleReset}
      onClose={onClose}
    >
      <div className="-mx-4 -my-1.5 snap-x snap-mandatory overflow-x-scroll hide-scrollbar">
        <div className="inline-block px-4">
          <ul className="flex -mx-1.5">
            {[...Array(MAX_ATTENDEES).keys()].map((c) => {
              const count = c + 1;

              return (
                <li key={count} className="p-1.5">
                  <button
                    className={clsx(
                      'snap-center flex items-center justify-center w-14 h-14 shrink-0 rounded-full text-lg',
                      count === attendees
                        ? 'bg-spoto text-white'
                        : 'bg-gray-100'
                    )}
                    onClick={() => {
                      setAttendees(count);
                      setTimeout(() => {
                        onClose();
                      }, 150);
                    }}
                  >
                    {count}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </FilterSheet>
  );
}

export const AttendeesFilterButton = ({ onClick }) => {
  const attendees = useStore((store) => store.hostsFilter.attendees);

  return (
    <Pill active={attendees !== 1} onClick={onClick}>
      <Person className="mr-2" />
      {attendees} {attendees === 1 ? 'person' : 'people'}
    </Pill>
  );
};
