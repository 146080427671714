import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import clsx from 'clsx';

import {
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonImage,
} from './Skeleton';

export default function HostCard({ imageSrc, name, address, url, isCompact }) {
  return (
    <div
      className={clsx(
        'group relative',
        isCompact && 'flex items-center bg-white p-2 rounded-md shadow-2xl'
      )}
    >
      <div>
        <div
          className={clsx(
            'relative aspect-w-9 aspect-h-5 shrink-0 rounded-md overflow-hidden bg-gray-100 group-hover:opacity-75 transition-opacity',
            isCompact ? 'w-[126px]' : 'w-full'
          )}
        >
          <Image
            layout="fill"
            objectFit="cover"
            quality={100}
            className=""
            // placeholder="blur"
            src={imageSrc}
            alt=""
          />
        </div>
      </div>

      <div className={clsx(isCompact ? 'pl-4' : 'pt-1')}>
        <h3 className={clsx('font-semibold', !isCompact && 'text-lg')}>
          {name}
        </h3>
        <p className="text-sm text-gray-500">{address}</p>
      </div>

      <Link href={url}>
        <a className="block cover-parent">
          <span className="sr-only">Show spot</span>
        </a>
      </Link>
    </div>
  );
}

const HostCardSkeleton = () => (
  <div>
    <SkeletonImage className="rounded-md" />
    <div className="pt-3 space-y-2">
      <SkeletonDisplayText />
      <SkeletonBodyText lines={1} />
    </div>
  </div>
);

HostCard.Skeleton = HostCardSkeleton;
