import React from 'react';

import Chair from 'images/outline/small/chair.svg';
import { TagsQuery } from 'queries';
import { useQuery } from 'urql';

import useStore from 'store';

import FilterSheet from './FilterSheet';
import Pill from './Pill';

function useTags() {
  const [result] = useQuery({
    query: TagsQuery,
  });

  return result;
}

export default function WorkTypeFilter({ show, onClose }) {
  const workType = useStore((store) => store.hostsFilter.workType);
  const setHostsFilter = useStore((store) => store.setHostsFilter);
  const resetHostsFilterByKey = useStore(
    (store) => store.resetHostsFilterByKey
  );

  const result = useTags();

  const { data } = result;

  const handleSave = onClose;
  const handleReset = () => {
    resetHostsFilterByKey('workType');
    onClose();
  };
  const setWorkType = (w) => setHostsFilter({ workType: w });

  return (
    <FilterSheet
      title="Filter by work type"
      show={show}
      onSave={handleSave}
      onReset={handleReset}
      onClose={onClose}
    >
      <div className="divide-y -my-3">
        {data?.tags
          .sort((a, b) => a.position - b.position)
          .map(({ id, label }) => (
            <label
              key={id}
              className="flex items-center py-3"
              onClick={() => {
                setTimeout(onClose, 150);
              }}
            >
              <input
                id={id}
                value={id}
                name="workType"
                type="radio"
                onChange={(e) => setWorkType(e.target.value)}
                checked={id === workType}
                className="h-4 w-4 text-spoto border-gray-500"
              />
              <span htmlFor={id} className="ml-3 block">
                {label}
              </span>
            </label>
          ))}
      </div>
    </FilterSheet>
  );
}

export const WorkTypeFilterButton = ({ onClick }) => {
  const workType = useStore((store) => store.hostsFilter.workType);
  const result = useTags();

  const { data, fetching, error } = result;

  const label = React.useMemo(() => {
    if (!workType || !data) {
      return 'Any work';
    }

    return data.tags.find(({ id }) => id === workType)?.label;
  }, [data, workType]);

  return (
    <Pill disabled={error || fetching} active={workType} onClick={onClick}>
      <Chair className="mr-2" />
      {label}
    </Pill>
  );
};
