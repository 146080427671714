import React from 'react';

import useStore from 'store';

import { BlankButton } from 'components/Button';

import AttendeesFilterSheet, { AttendeesFilterButton } from './Attendees';
import AvailabilityFilterSheet, {
  AvailabilityFilterButton,
} from './Availability';
import WorkTypeFilterSheet, { WorkTypeFilterButton } from './WorkType';

export default function HostsFilter() {
  const scrollRef = React.useRef();

  const clearAllHostsFilter = useStore((store) => store.clearAllHostsFilter);

  const [showAvailabilityFilter, setShowAvailabilityFilter] =
    React.useState(false);
  const [showWorkTypeFilter, setShowWorkTypeFilter] = React.useState(false);
  const [showAttendeesFilter, setShowAttendeesFilter] = React.useState(false);

  const handleReset = () => {
    clearAllHostsFilter();

    setTimeout(() => {
      scrollRef.current?.scrollTo({ left: 0, behavior: 'smooth' });
    }, 1);
  };

  return (
    <React.Fragment>
      <WorkTypeFilterSheet
        show={showWorkTypeFilter}
        onClose={() => setShowWorkTypeFilter(false)}
      />
      <AvailabilityFilterSheet
        show={showAvailabilityFilter}
        onClose={() => setShowAvailabilityFilter(false)}
      />
      <AttendeesFilterSheet
        show={showAttendeesFilter}
        onClose={() => setShowAttendeesFilter(false)}
      />

      <div
        ref={scrollRef}
        className="-mx-4 -my-1 snap-x snap-mandatory overflow-x-scroll hide-scrollbar"
      >
        <div className="inline-block px-4">
          <ul className="flex -mx-1">
            <li className="snap-center shrink-0 p-1">
              <AvailabilityFilterButton
                onClick={() => setShowAvailabilityFilter(true)}
              />
            </li>

            <li className="snap-center shrink-0 p-1">
              <WorkTypeFilterButton
                onClick={() => setShowWorkTypeFilter(true)}
              />
            </li>

            <li className="snap-center shrink-0 p-1">
              <AttendeesFilterButton
                onClick={() => setShowAttendeesFilter(true)}
              />
            </li>

            <li className="shrink-0 flex items-center">
              <BlankButton onClick={handleReset}>Reset all</BlankButton>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
