import React from 'react';

import Button, { BlankButton } from 'components/Button';
import Sheet from 'components/Sheet';

export default function FilterSheet({
  title,
  show,
  onSave,
  onReset,
  onClose,
  children,
}) {
  return (
    <Sheet title={title} show={show} onClose={onClose}>
      {children}
      <div className="pt-8 space-y-2">
        <Button onClick={onSave}>Save</Button>
        <BlankButton onClick={onReset} fullWidth>
          Clear filter
        </BlankButton>
      </div>
    </Sheet>
  );
}
